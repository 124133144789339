import React from "react"
import PropTypes from "prop-types"
class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: false,
      showVideo: false,
      videoLoaded: false,
    }
    this.video = React.createRef();
    this.image = React.createRef();
    this.play = React.createRef();
    this.handlePlay = this.handlePlay.bind(this);
    this.handleVideoLoad = this.handleVideoLoad.bind(this);
  }

  handlePlay(){
    this.setState({controls: true, showVideo: true});
    this.play.current.classList.add('trans');
    this.image.current.classList.add('trans');
    this.video.current.play();
  }

  handleVideoLoad(){
    this.setState({videoLoaded: true})
  }

  render () {
    return (
      <div className={`relative fade-in`}>
        <video
          controlsList= "nodownload"
          crossOrigin="anonymous"
          ref={this.video}
          className={`toggle-trans ${this.state.showVideo ? '' : 'trans'}`}
          style={{
            width: '100%',
            // maxWidth: '1000px'
          }}
          src={this.props.src}
          alt={this.props.alt}
          controls={this.state.controls}
          onLoadedData={this.handleVideoLoad}
        >
          <track kind="subtitles" src={this.props.subtiles} srcLang="en" label = "English" default />
        </video>
        <img
          ref={this.image}
          src={this.props.poster}
          alt="poster"
          className='absolute top left full-width toggle-trans'
        />
        <div
          className="absolute hand-on-hover toggle-trans lp circle-radius row align-center-middle blur-bg fade-in"
          ref={this.play}
          id='video-play-button'
        >
          <i
            className='fa fa-play lml dark-gray fade-in'
            onClick={this.handlePlay}
          ></i>  
        </div>
        
      </div>

    );
  }
}

VideoPlayer.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  poster: PropTypes.string
};
export default VideoPlayer
