// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { start } from 'turbolinks'
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")

require("foundation-sites");
const startFoundation = () => {
  $(document).foundation();
}

$(document).on('turbolinks:load', function () {
  startFoundation();
  gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin);

  // Initialize animations for elements with the class 'animate-section'
  gsap.utils.toArray('.animate-section').forEach(section => {
    gsap.from(section, {
      duration: 1, // Duration of the animation
      autoAlpha: 0, // Fade in from 0 opacity
      ease: 'none',
      scrollTrigger: {
        trigger: section,
        start: 'top 80%', // Start the animation when the top of the section hits 80% of the viewport
        end: 'bottom 20%', // End the animation when the bottom of the section leaves 20% of the viewport
        toggleActions: 'play none none reverse', // Defines how the animation behaves on scroll into and out of view
      }
    });
  });

  gsap.utils.toArray('article.body img, article.body trix-content div').forEach((img, i) => {

    // gsap from the left to right and fade in

    gsap.from(img, {
      duration: 1.5, // Longer duration for a more pronounced effect
      autoAlpha: 0, // Start from invisible
      border: '1px solid #000',
      x: i % 2 === 0 ? -100 : 100, // Slide in from the left or right
      ease: 'power2.out', // Use a more dynamic easing function
      scrollTrigger: {
        trigger: img,
        start: 'top 65%', // Adjust the start position for the animation to trigger later
        end: 'bottom 10%', // Adjust the end position
        toggleActions: 'play none none reverse',
        markers: false, // Set to true if you want to see the start and end points during development
      }});
  });
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
require("../app/close_flash.js")
// require("app/stickyHeader");
require("app/addLastClickedParamToForm");
require("app/submitFormOnChange");
require("app/submitSearchOnInput");
require("app/toggleFocussed");

// hide the Images and "Design By Community" on home when hovering over circles
// require("app/toggleHeroOverlay");

// require("app/toggleCircleContent");

require("app/direct_uploads");
require("app/triggerFileAttached");
require("app/setDataIndexForMediaInputs");
require("app/hoverAnimations");
require("app/setSearchPopupHeight");
require("app/square");
require("app/hoverImageLabel");
// require("app/deselectableRadioButtons");
require("app/homeBalls");
require("app/addClassBeforeNavigation");
require("app/stickyTrixToolbar");
require("jquery")
require("@nathanvda/cocoon")

import square from '../app/square';
import hoverImageLabel from '../app/hoverImageLabel';
import addClassBeforeNavigation from '../app/addClassBeforeNavigation';
import "controllers";

export {
  square,
  startFoundation,
  hoverImageLabel,
  addClassBeforeNavigation
}
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
